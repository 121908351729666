import { mapState, mapMutations } from "vuex";
export const book = {
    computed: {
        ...mapState({
            entry: state => state.Entry,
            datiUtente: state => state.Entry.datiUtente,
            form: state => state.Utils.form,
            primaria: state => state.Utils.primaria,
            cliente: state => state.Ente.cliente,
            recaptchaToken: state => state.Utils.recaptchaToken,
            risorsa: state => state.Entry.risorsa,
            retEntry: state => state.Entry.retEntry
        }),
        public_primary() {
            return this.form.filter(e => e.primaria == true)[0].codice;
        }
    },
    data() {
        return {
            loading: false,
            reservationCompleted: false,
            done: false,
            anyErrors: false,
            doneReservations: [],
        };
    },
    watch: {
        anyErrors(value) {
            if (!value) return;
            this.rollback();
        }
    },
    methods: {
        ...mapMutations({
            setRetEntry: "Entry/SET_RETENTRY",
            setRecaptcha: "Utils/SET_RECAPTCHATOKEN",
            setError: "Utils/SET_ERRORS",
            clearErrors: "Utils/CLEAR_ERRORS",
            resetReturnEntry: "Entry/RESET_RETURN_ENTRY"
        }),
        async singleBook(dati, index) {
            dati = Object.assign(...dati);
            const utente = this.form
                .filter(e => e.posizione == 1)
                .map(e => {
                    return {
                        val: dati[e.codice],
                        codice: e.codice
                    };
                });

            const servizio = this.form
                .filter(e => e.posizione == 2)
                .map(e => {
                    return {
                        val: dati[e.codice],
                        codice: e.codice
                    };
                });

            let payload = {
                reservation_number: index,
                cliente: this.cliente.Codice,
                start_time: this.entry.data
                    ? this.entry.data.getTime() / 1000
                    : null,
                end_time: this.entry.end
                    ? this.entry.end.getTime() / 1000
                    : null,
                durata: this.entry.durata,
                entry_type: this.entry.servizio.id,
                area: this.entry.area.id,
                public_primary: dati[this.public_primary],
                utente: Object.assign(
                    {},
                    ...utente.map(object => {
                        return { [object.codice]: object.val };
                    })
                ),
                servizio: Object.assign(
                    {},
                    ...servizio.map(object => {
                        return { [object.codice]: object.val };
                    })
                ),
                risorsa: this.risorsa ? this.risorsa.risorsa.id : null,
                recaptchaToken: this.recaptchaToken,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };

            try {
                let res = await this.$http.post("/api/entry/store", payload);
                this.setRetEntry(res.data);
                this.$set(
                    this.doneReservations,
                    this.doneReservations.length,
                    res.data
                );
            } catch (error) {
                let formattedError = {
                    message:
                        error.response && error.response.data
                            ? error.response.data.message
                            : "Unknown error",
                    data:
                        error.response && error.response.data
                            ? error.response.data.data
                            : {}
                };
                this.setError(formattedError);

                this.anyErrors = true;
                console.error("Error in singleBook for dati:", dati, error);
                throw error;
            }
        },
        async book() {
            this.reservationCompleted = false;

            // Controllo per quando si ricarica la pagina
            if (!this.entry.area || !this.entry.data || !this.entry.servizio) {
                return this.setError({
                    message: "server.errors.reload_riepilogo",
                    data: {}
                });
            }

            this.loading = true;

            try {
                for (let i = 0; i < this.datiUtente.length; i++) {
                    await this.singleBook(this.datiUtente[i], i);
                }
                this.reservationCompleted = true;
            } catch (error) {
                console.error("Error in booking process:", error);
            } finally {
                this.loading = false;
            }
        },

        async rollback() {
            try {
                for (const e of this.doneReservations) {
                    await this.$http.post(
                        `/api/entry/delete/${e.codice_prenotazione}?chiave=${e.user_primary}`,
                        { force: true }
                    );
                }

                this.resetReturnEntry();
                this.doneReservations.splice(0, this.doneReservations.length);
                console.log("Rollback completed successfully.");
            } catch (error) {
                console.error("Error in rollback:", error);
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        async fullRollBack() {
            this.anyErrors = false;
            return new Promise((res, rej) =>
                this.rollback()
                    .then(() => this.resetReturnEntry())
                    .then(() => res())
                    .catch(e => {
                        this.setError(e);
                        rej();
                    })
            );
        }
    },
    beforeRouteLeave(to, _, next) {
        if (
            ![
                this.$routes.PRENOTA.CONFIRMED,
                this.$routes.SERVIZI_BREVI.CONFIRMED
            ].includes(to.name)
        ) {
            this.clearErrors();
            this.fullRollBack().then(next());
        } else {
            next();
        }
    }
};
