// import axios from "axios";
import { mapMutations, mapState } from "vuex";
/**
 *  * recupera le durate del servizio da mostrare nella select
 *   */
export const showsDurate = {
    computed: {
        ...mapState({
            durate: state => state.Entry.servizio ? state.Entry.servizio.durate_select : null,
            durata: state => state.Entry.servizio ? state.Entry.servizio.durata : null,
            durataSelezionata: state => state.Entry.durata,
            area: state => state.Entry.area
        })
    },
    methods: {
        ...mapMutations({
            setDurate: "Utils/SET_DURATE",
            setDurata: "Entry/SET_DURATA"
        }),
        /**
         *  @vuese
         *  popola la select delle durate
         *
         * */
        populateSelect() {

            //caso di durata massima disponibile
            if (
                (this.durate && this.durate[0] === "max") ||
                this.durate === "max"
            ) {
                this.setDurata("max");
                return;
            }

            //caso in cui durata sia definita
            if (this.durata && this.durata != 0) {
                this.setDurata(this.durata);
                return;
            }
            //caso in cui le durate_select non siano definite
            if (!this.durate) return;
            //caso in cui le durate_select siano definite
            //mappa orari in un oggetto {lable : '1 ora' , value : 3600}
            this.orari = this.durate.map(e => {
                return { label: this.secondsToString(e, this), durata: e };
            });
            //popola select con le durate e imposta prima durata di default
            if (!this.durataSelezionata || !this.durate.includes(this.durataSelezionata)) {
                this.setDurata(+this.durate[0]);
            }
            this.setDurate(this.orari);
        },
        durataInSelect(value) {
            return this.durate.includes(value)
        }
    }

};
