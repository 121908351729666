var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entry.servizio && !_vm.entry.data)?_c('div',{staticClass:"form-header--info",attrs:{"id":"formheader-info"}},[_c('div',{staticClass:"form-header--info--box"},[_c('div',{staticClass:"form-header--info-luogo"},[(
                    _vm.entry.servizio &&
                        Object.keys(_vm.entry.servizio).length != 0
                )?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.entry.servizio.type, html: true }),expression:"{ content: entry.servizio.type, html: true }"}],staticClass:"title servizio text-truncate"},[_c('i',{staticClass:"fas fa-concierge-bell mr-1 mt-1"}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.entry.servizio.type)}})]):_c('div',{staticClass:"title servizio text-truncate"},[_c('i',{staticClass:"fas fa-concierge-bell mr-1 mt-1"}),_c('span',{staticClass:"title text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("servizio")))])]),(_vm.entry.area)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.entry.area.area_name, html: true }),expression:"{ content: entry.area.area_name, html: true }"}],staticClass:"title luogo text-truncate"},[_c('i',{staticClass:"far fa-building mr-1 mt-1"}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.entry.area.area_name)}})]):_c('span',{staticClass:"title text-truncate"},[_c('i',{staticClass:"far fa-building mr-2 mt-1"}),_vm._v(_vm._s(_vm.$t("sede")))])]),(
                _vm.entry.servizio &&
                    Object.keys(_vm.entry.servizio).length != 0 &&
                    !_vm.servizio.servizio_breve
            )?_c('div',{staticClass:"form-header--info-durata"},[(
                    _vm.entry.servizio &&
                        (_vm.entry.servizio.durata == 0 ||
                            _vm.entry.servizio.durata == null) &&
                        !_vm.entry.servizio.resource_grid &&
                        _vm.entry.servizio.durate_select != 'max'
                )?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('form.header.durata-select')),expression:"$t('form.header.durata-select')"}]},[_c('label',{ref:"durata",staticClass:"mr-2",attrs:{"for":"durata"}},[_c('i',{staticClass:"far fa-clock mr-1"}),_vm._v(_vm._s(_vm.$t("form.header.durata-select")))]),_c('select',{staticClass:"time-pill",class:{
                        blink: _vm.notifySelect
                    },attrs:{"name":"durata","id":"durata"},on:{"change":function($event){return _vm.callSetDurata($event)}}},_vm._l((_vm.orari),function(n,i){return _c('option',{key:n.durata,attrs:{"default":i == 0},domProps:{"value":n.durata,"selected":_vm.durataSelected
                                ? n.durata == _vm.durataSelected
                                : i == 0}},[_vm._v(" "+_vm._s(n.label)+" ")])}),0)]):(_vm.entry.servizio.durate_select[0] === 'max')?_c('div',[_c('label',{ref:"durata",staticClass:"mr-2",attrs:{"for":"durata"}},[_c('i',{staticClass:"far fa-clock mr-1"}),_vm._v(" "+_vm._s(_vm.$t("form.header.durata-max")))])]):(!_vm.entry.servizio.resource_grid)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('form.header.durata')),expression:"$t('form.header.durata')"}]},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" "+_vm._s(_vm.$t("form.header.durata"))+" "+_vm._s(_vm.secondsToString(_vm.entry.durata, this))+" ")]):_vm._e()]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }