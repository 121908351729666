
<template>
  <div class="row" id="prenota">
    <div class="col-12 p-0">
      <error-banner v-if="errors" :backTo="$route.name === 'Riepilogo' ? 'Calendario' : null" />
      <div class="booking">
        <div class="booking--header overflow-hidden">
          <form-header :nextActive="next" :isTimePicker="isTimePicker" />
        </div>
        <div class="booking--content">
          <div class="loading h-100 mx-auto" v-if="formLoading">
            <video autoplay loop muted playsinline width="100px">
              <source src="../assets/easyload-xl.webm" type="video/webm" />
            </video>
          </div>
          <div v-else>
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive" :key="$route.name" @next="next = true"
                data-cypress="Prenota"></router-view>
            </keep-alive>

            <router-view v-if="!$route.meta.keepAlive" :key="$route.name" @next="next = true"
              data-cypress="Prenota"></router-view>
            <div v-if="recaptchaSize == 'large' && showCaptcha">
              <div class="d-flex flex-column align-items-center my-5">
                <h3 class="m-3">
                  Compila recaptcha
                </h3>
                <vue-recaptcha v-if="showCaptcha" ref="recaptcha" size="large" :sitekey="recaptchaSiteKey"
                  @verify="onCaptchaVerified" @expired="onCaptchaExpired" @error="execute" :loadRecaptchaScript="true"
                  language="en"></vue-recaptcha>
              </div>

            </div>
            <div
              v-else-if="this.configs.general.captcha_control && this.configs.general.captcha_control != 0 && recaptchaSize != 'large'">
              <vue-recaptcha ref="recaptcha" size="invisible" :sitekey="recaptchaSiteKey" @verify="onCaptchaVerified"
                @expired="onCaptchaExpired" @error="execute" :loadRecaptchaScript="true" language="en"></vue-recaptcha>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import { book } from "../mixins/book";

import FormHeader from "../components/formHeader/FormHeader";
import ErrorBanner from "../components/ErrorBanner";
// @group Views
//Pagina della prenotazione
//ha al suo interno un router-view dove i vari step della prenotazione
//vengono renderizzati
export default {
  mixins: [book],
  components: {
    FormHeader,
    VueRecaptcha,
    ErrorBanner,
  },
  data() {
    return {
      next: false,
      isTimePicker: false,
    };
  },
  ready() {
    window.onbeforeunload = this.onUnload;
    window.onblur = this.onUnload;
    window.onmouseout = this.onUnload;
    // window.addEventListener("unload", this.onUnload);
  },
  // beforeDestroy() {
  //   window.removeEventListener("unload", this.onUnload);
  // },
  computed: {
    ...mapState({
      formLoading: (state) => state.Utils.formLoading,
      configs: (state) => state.Ente.configs,
      errors: (state) => state.Utils.errors,
      retEntry: (state) => state.Entry.retEntry
    }),
    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCHA_KEY;
    },
    showCaptcha() {
      return !!this.$route.name.match('Riepilogo') && !this.retEntry &&
        this.configs.general.captcha_control &&
        this.configs.general.captcha_control != 0;
    },
    recaptchaSize() {
      return this.configs.general.captcha_size;
    }
  },
  methods: {
    //@vuese
    //esegue controllo captcha token
    execute() {
      this.$refs.recaptcha.execute();
    },
    //@vuese
    //set del recaptcha token nello state e chiama book()
    onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();
      new Promise((resolve) => {
        this.setRecaptcha(recaptchaToken);
        resolve();
      }).then(() => this.book());
    },
    /**
     * @vuese
     * ricarica recaptcha quando è scaduto
     */
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    onUnload() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped >
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.row {
  margin-top: 48px !important;
}

.booking {
  margin: 0rem auto 3rem auto;
  border: 1px solid #666;
  border-radius: 10px;
  padding: 0;
}

.booking--header {
  display: flex;
  flex-direction: column;
}

.booking--content {
  width: 100%;
  display: block;
  /* margin: 0 0 50px 0;
   padding: 1rem 2rem; */
}

@media screen and (max-width: 767px) {
  .row {
    margin-top: 0 !important;
  }

  .booking {
    width: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none;
    margin: 0;
    /* min-height: 100vh; */
  }

  .booking--content {
    padding: 0rem;
  }
}
</style>
