import { mapState } from "vuex";
import { i18n } from "../i18n";

export const showsEndTime = {
    computed: {
        ...mapState({
            data: state => state.Entry.data,
            end: state => state.Entry.end,
            durata: state => state.Entry.durata,
            configs: state => state.Ente.configs
        }),
        lang() {
            const array = i18n.locale.split("_");
            const length = array.length;
            return array[length - 1];
        },
        showsEndTime() {
            return (
                this.configs &&
                this.configs.griglia &&
                this.configs.griglia.mostra_ora_fine &&
                this.configs.griglia.mostra_ora_fine != 0
            );
        },
        hideEndTimeFromSoftware() {
            return this.configs &&
                this.configs.portale_pubblico &&
                this.configs.portale_pubblico.nascondi_ora_fine &&
                this.configs.portale_pubblico.nascondi_ora_fine == 1;
        },
        endTime() {
            if (this.hideEndTimeFromSoftware)
                return null;
            return this.end
                .toLocaleTimeString(this.lang)
                .replace(/(.*)\D\d+/, "$1");
        },

    }
};
