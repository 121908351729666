import { mapState } from "vuex";

export const publicPosition = {
    computed: {
        ...mapState({
            publicPosition: state =>
                state.Ente.configs.general.public_position == 1
        })
    }
};
