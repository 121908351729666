<template>
  <div class="form-header">
    <!-- :style="style" -->
    <form-header-navigation :isTimePicker="isTimePicker"></form-header-navigation>
    <transition name="slide">
      <form-header-riepilogo></form-header-riepilogo>
    </transition>
    <transition name="slide">
      <form-header-info></form-header-info>
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import FormHeaderNavigation from "./FormHeaderNavigation";
import FormHeaderRiepilogo from "./FormHeaderRiepilogo";
import FormHeaderInfo from "./FormHeaderInfo";
/**
* @vuese
* @group FormHeader
* Componente che raggruppa tutti gli elementi del Form Header
*/
export default {
  name: "FormHeader",
  props: {
    isTimePicker: {
      type: Boolean,
    },
  },
  components: {
    FormHeaderNavigation,
    FormHeaderRiepilogo,
    FormHeaderInfo,
  },
  computed: {
    ...mapState({
      entry: (state) => state.Entry,
    }),
    style() {
      let height = 50;
      if (this.entry.servizio) {
        height += 50;

        if (this.entry.data) {
          height += 50;
        }
      }

      return {
        height: `${height}px`,
      };
    },
  },

  methods: {
    ...mapMutations({
      setDurata: "Entry/SET_DURATA",
    }),
  },
};
</script>

<style scoped>
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s, transform 0.3s ease-in-out;
}

.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid black;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif !important;
  transition: all 0.3s;
}

/* .list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
  top: -50px;
  z-index: -10;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
} */

@media screen and (max-width: 600px) {
  .form-header--info {
    padding: 0.5rem 0.3rem;
  }
}
</style>
