<template>
    <div
        class="form-header--riepilogo"
        :class="{ 'd-none': $route.name.includes('Riepilogo') }"
        v-if="entry.data"
        data-tour-step="11"
    >
        <div
            class="form-header--riepilogo-riepilogo"
            v-if="
                $route.name.includes('DatiUtente') ||
                    $route.name.includes('Confirmed')
            "
        >
            <div class="form-header--riepilogo-riepilogo--luogo">
                <span class="mr-3">
                    <i class="fas fa-concierge-bell mr-1 mt-1"></i>
                    <span v-html="entry.servizio.type"></span>
                </span>
                <span>
                    <i class="far fa-building mr-2 mt-1"></i>
                    <span v-html="entry.area.area_name"></span>
                </span>
                <span v-if="risorsa && !publicPosition">
                    <i class="fas fa-laptop-house"></i>
                    {{ risorsa.resource_name }}
                </span>
            </div>
            <div class="form-header--riepilogo-riepilogo--dati">
                <div>
                    <i class="far fa-calendar-alt" v-tooltip="$t('data')"></i>
                    {{
                        entry.data.toLocaleDateString(lang, {
                            weekday: "short",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })
                    }}
                </div>
                <div>
                    <i class="far fa-clock" v-tooltip="$t('orario')"></i>
                    {{
                        entry.data.toLocaleTimeString(lang, {
                            hour: "2-digit",
                            minute: "2-digit"
                        })
                    }}
                    <template v-if="!hideEndTimeFromSoftware"> - {{ endTime }}</template>
                </div>

                <div
                    v-if="
                        configs.general.multi_prenotazione != 0 &&
                            entry.servizio.multi_prenotazione
                    "
                >
                    <div class="sr-only">{{ $t("numero_prenotazioni") }}</div>
                    <i
                        class="fas fa-user-friends"
                        v-tooltip="$t('numero_prenotazioni')"
                    ></i>
                    {{ reservationsNumber }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { showsEndTime } from "../../mixins/showsEndTime";
import { publicPosition } from "../../mixins/publicPosition";
/**
 * @vuese
 * @group FormHeader
 * Componente con riepilgo generale
 */
export default {
    name: "FormHeaderRiepilogo",

    mixins: [publicPosition, showsEndTime],

    computed: {
        ...mapState({
            entry: state => state.Entry,
            form: state => state.Utils.form,
            datiUtente: state => state.Entry.datiUtente,
            retEntry: state => state.Entry.retEntry,
            reservationsNumber: state => state.Entry.numberOfReservations,
            errors: state => state.Utils.errors,
            configs: state => state.Ente.configs,
            risorsa: state =>
                state.Entry.risorsa ? state.Entry.risorsa.risorsa : null
        })
    },
    data() {
        return {
            loading: false,
            disabled: true
        };
    }
};
</script>

<style scoped>
.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}

.slide-enter-active,
.slide-leave-active {
    transition: opacity 0.4s, transform 0.4s ease-in-out;
}

.loading {
    /* background-color: rgba(209, 209, 209, 0.322); */
    height: 100%;
}

.form-header-riepilogo-riepilogo--box {
    border: 1px solid rgb(211, 211, 211);
    padding: 0.5rem 1rem;
    /* border-radius: 10px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20%;
}

ul {
    padding: 0.3rem;
}

.form-header--riepilogo-riepilogo {
    background-color: white;
    display: flex;
    background-color: white;
    border: 1px solid rgb(211, 211, 211);
    padding: 1rem 1rem;
    border-radius: 10px;
    justify-content: center;
    flex-direction: column;
    color: #666;
    /* min-height: 100px; */
}

.form-header--riepilogo-riepilogo.lg {
    background-color: white;
    padding: 0.9rem 1.5rem;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}

.form-header--riepilogo-riepilogo--luogo {
    margin-bottom: 0.3rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.form-header--riepilogo-riepilogo--luogo.lg,
.form-header--riepilogo-riepilogo--datiutente {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
}

.form-header--riepilogo-riepilogo--datiutente span:not(.dato) {
    font-size: 0.9rem;
    font-weight: bold;
    word-break: break-all;

    text-transform: capitalize;
}

.dato {
    word-break: break-all;
    display: inline-block;
}

.form-header--riepilogo-riepilogo--datiutente li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0.3rem 0;
    padding: 0.4rem 0;
    border-bottom: 1px solid rgb(211, 211, 211);
}

.form-header--riepilogo-riepilogo--luogo.lg li {
    margin-bottom: 10px;
    display: flex;
}

.form-header--riepilogo-riepilogo--luogo.lg li i {
    margin-right: 20px;
    font-size: 1.2rem;
}

.form-header--riepilogo-riepilogo--dati.lg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    font-size: 1rem;
    margin-top: 1rem auto;
    flex-wrap: wrap;
}

.form-header--riepilogo-riepilogo--dati.lg li {
    margin-right: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-header--riepilogo-riepilogo--dati.lg li i {
    margin-right: 10px;
}

.form-header--riepilogo-riepilogo--dati {
    display: flex;
    justify-content: space-around;
    font-size: 0.8rem;
}

.form-header--riepilogo {
    background: #eee;
    width: 100%;

    padding: 1rem 8rem;

    overflow: hidden;
    /* border-radius: 10px; */
}

@media screen and (max-width: 800px) {
    .form-header--riepilogo-riepilogo.lg {
        width: 100%;
        padding: 0;
    }

    .form-header--riepilogo {
        padding: 0.8rem 0.3rem;
    }

    .form-header-riepilogo-riepilogo--box {
        padding-left: 1rem;
    }
}
</style>
