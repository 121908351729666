<template>
    <div
        class="form-header--info"
        id="formheader-info"
        v-if="entry.servizio && !entry.data"
    >
        <div class="form-header--info--box">
            <div class="form-header--info-luogo">
                <div
                    v-if="
                        entry.servizio &&
                            Object.keys(entry.servizio).length != 0
                    "
                    v-tooltip="{ content: entry.servizio.type, html: true }"
                    class="title servizio text-truncate"
                >
                    <i class="fas fa-concierge-bell mr-1 mt-1"></i>
                    <span v-html="entry.servizio.type"></span>
                </div>
                <div v-else class="title servizio text-truncate">
                    <i class="fas fa-concierge-bell mr-1 mt-1"></i>
                    <span class="title text-truncate">
                        {{ $t("servizio") }}</span
                    >
                </div>
                <div
                    v-if="entry.area"
                    v-tooltip="{ content: entry.area.area_name, html: true }"
                    class="title luogo text-truncate"
                >
                    <i class="far fa-building mr-1 mt-1"></i>
                    <span v-html="entry.area.area_name"></span>
                </div>
                <span v-else class="title text-truncate">
                    <i class="far fa-building mr-2 mt-1"></i
                    >{{ $t("sede") }}</span
                >
            </div>
            <div
                class="form-header--info-durata"
                v-if="
                    entry.servizio &&
                        Object.keys(entry.servizio).length != 0 &&
                        !servizio.servizio_breve
                "
            >
                <div
                    v-if="
                        entry.servizio &&
                            (entry.servizio.durata == 0 ||
                                entry.servizio.durata == null) &&
                            !entry.servizio.resource_grid &&
                            entry.servizio.durate_select != 'max'
                    "
                    v-tooltip="$t('form.header.durata-select')"
                >
                    <label for="durata" ref="durata" class="mr-2"
                        ><i class="far fa-clock mr-1"></i
                        >{{ $t("form.header.durata-select") }}</label
                    >
                    <select
                        name="durata"
                        id="durata"
                        :class="{
                            blink: notifySelect
                        }"
                        class="time-pill"
                        @change="callSetDurata($event)"
                    >
                        <option
                            v-for="(n, i) in orari"
                            :key="n.durata"
                            :value="n.durata"
                            :default="i == 0"
                            :selected="
                                durataSelected
                                    ? n.durata == durataSelected
                                    : i == 0
                            "
                        >
                            {{ n.label }}
                        </option>
                    </select>
                </div>
                <div v-else-if="entry.servizio.durate_select[0] === 'max'">
                    <label for="durata" ref="durata" class="mr-2"
                        ><i class="far fa-clock mr-1"></i>
                        {{ $t("form.header.durata-max") }}</label
                    >
                </div>
                <div
                    v-else-if="!entry.servizio.resource_grid"
                    v-tooltip="$t('form.header.durata')"
                >
                    <i class="far fa-clock"></i>
                    {{ $t("form.header.durata") }}
                    {{ secondsToString(entry.durata, this) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import { showsDurate } from "../../mixins/showsDurate";
/**
 * @vuese
 * @group FormHeader
 * Primo componente visualizzato nel form header mostra info di sede servizio e durata
 */
export default {
    name: "FormHeaderInfo",
    mixins: [showsDurate],

    computed: {
        ...mapState({
            entry: state => state.Entry,
            durataSelected: state => state.Entry.durata,
            servizio: state => state.Entry.servizio
        })
    },
    data() {
        return {
            orari: null,
            notifySelect: false
        };
    },
    watch: {
        servizio() {
            this.populateSelect();
        },
        durata(value) {
            if (value == null) this.populateSelect();
        }
    },
    mounted() {
        this.populateSelect();
    },
    activated() {
        this.populateSelect();
    },
    methods: {
        ...mapMutations({
            setDurata: "Entry/SET_DURATA"
        }),
        /**
         * @vuese
         * chiama mutation set durata per impostare la durata del servizio
         */
        callSetDurata(event) {
            this.notifySelect = false;
            this.setDurata(event.target.value);
        }
    }
};
</script>
<style scoped>
.form-header--info-luogo,
.form-header--info-durata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-header--info {
    background: #eee;
    width: 100%;

    padding: 1rem 8rem;

    overflow: hidden;
}

.form-header--info--box {
    background-color: white;

    display: flex;

    background-color: white;

    border: 1px solid rgb(211, 211, 211);
    padding: 0.5rem 1rem;
    border-radius: 10px;

    justify-content: center;
    flex-direction: column;
    color: #666;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.form-header--info div:not(:last-child) {
    margin-bottom: 1rem;
}

.blink {
    animation: blinkingBorder 1.2s infinite;
}

@keyframes blinkingBorder {
    0% {
        border: 2px solid red;
    }

    49% {
        border: 2px solid red;
    }

    60% {
        border: 2px solid black;
    }

    99% {
        border: 2px solid black;
    }

    100% {
        border: 2px solid red;
    }
}

@media screen and (max-width: 750px) {
    .form-header--calendar {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .form-header--info-box {
        background-color: white;
        padding: 0.9rem 1.5rem;

        color: #666;
    }

    .form-header--info {
        padding: 0.9rem 1.5rem;
    }

    .title {
        width: 100% !important;
        /* margin: 1rem; */
        word-break: break-all;
    }

    .form-header--info-durata {
        align-items: flex-start;
    }
}
</style>
