<template>
  <div
    class="form-header--navigation"
    :style="{ backgroundColor: coloreCliente ? color : '' }"
  >
    <div class="container">
      <div class="row">
        <div class="col-2">
          <router-link
            v-if="!errors && formHeaderLabel.prev"
            class="form-header--navigation--btn"
            :to="formHeaderLabel.prev"
            ref="prev"
          >
          <span class="sr-only">{{$t('buttons.back')}}</span> 
            <i class="fas fa-arrow-left"></i>
          </router-link>
          <div v-else class="form-header--navigation--btn cursor-none"></div>
        </div>
        <div class="col-8 d-flex justify-content-center align-items-center">
          <div class="form-header--navigation-title">
            {{ $t(formHeaderLabel.name) }}
            - {{ $t("form.menu.passo", { numero: formHeaderLabel.number.passo, totale : formHeaderLabel.number.di }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
/**
 * @vuese
 * @group FormHeader
 * Tasti di navigazione del form
 */
export default {
  name: "FormHeaderNavigation",
  data() {
    return {
      routes: ["servizi", "Calendario", "DatiUtente", "Riepilogo"],
    };
  },

  computed: {
    ...mapState({
      retEntry: (state) => state.Entry.retEntry,
      isTimePicker: (state) => state.Utils.timePicker,
      color: (state) => state.Ente.colors.sfondo[0],
      errors: (state) => state.Utils.errors,
      formHeaderLabel: (state) => state.Utils.formHeaderLabel,
    }),
    coloreCliente() {
      return process.env.VUE_APP_CARD_COLOR;
    },
  },
  methods: {
    ...mapMutations({
      setTourDone: "Utils/SET_TOUR_DONE",
    }),
    restartTour() {
      this.setTourDone(false);
    },
  },
};
</script>

<style scoped>
.form-header--navigation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background: #505a5e;
  color: white;
  /* padding: 1rem; */
  /* min-height: 4rem; */
  border-radius: 8px 8px 0 0;
}
.form-header--navigation--btn {
  height: 100%;
  padding: 1.3rem;
  text-decoration: none;
  color: inherit;
  /* width: 50px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin: 0 30px; */
  cursor: pointer;
  /* padding: 0.5rem 3rem; */
}

.form-header--navigation--btn:active,
.form-header--navigation--btn:hover {
  text-decoration: none;
  color: red;
  outline: none;
}
@media screen and (max-width: 768px) {
  .form-header--navigation {
    border-radius: 0;
  }
  .form-header--navigation--btn {
    /* margin: 0 20px; */
  }
}
.form-header--navigation--btn .cursor-none {
  cursor: none;
}
</style>